import { ThemeProvider } from '@hertz/storybook'
import * as React from 'react'
import { Provider } from 'react-redux'

import './src/style/global.css'
import { AppHelmet } from './src/appcomponents/AppHelmet'
import store from './src/store/store'

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <ThemeProvider brand="Hertz">
    <Provider store={store}>
      <AppHelmet />
      {element}
    </Provider>
  </ThemeProvider>
)
