import { RootState } from '@store/store'
import getBrandFavicon from '@utils/getBrandFavicon'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

export const AppHelmet = () => {
  const brandFromSlice = useSelector((state: RootState) => state.contract.brand)
  const faviconUrl = getBrandFavicon(brandFromSlice)

  return (
    <Helmet>
      <title>Hertz Mypage</title>
      <link rel="icon" type="image/x-icon" href={faviconUrl} />
    </Helmet>
  )
}
